<template>
  <div class="row mx-0 mb-1 position-relative">
    <slot />
    <div class="col-12 p-0">
      <div :for="id" class="form-label" v-if="label" v-html="label" :class="{'required': required}"></div>
      <input
        class="form-control medium"
        :class="[classErrorObject, color, size]"
        :type="type"
        :id="id"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        v-model="value"
        @blur="notFocus()"
      >
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        <span v-if="error.$message">{{ error.$message }}</span>
        <span v-else>{{ error }}</span>
      </div>
      <div class="isError text-center" v-if="theError" >
        {{ theError }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    id: {
      type: String,
      default: Math.random().toString(20).substr(2, 6)
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default (arrayProps) {
        return []
      }
    },
    hasErrors: {
      type: Number,
      default: 0
    },
    autocomplete: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theError: {
      type: String,
      default: ''
    },
    required: {
      type: String,
      default: ''
    },
    watchFocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'checkFocus'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    classErrorObject () {
      return {
        error: this.hasErrors > 0 || this.theError
      }
    }
  },
  methods: {
    notFocus () {
      if (this.watchFocus) {
        this.$emit('checkFocus')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control.small {
    border-width: 1px;
    font-size: 0.8rem;
    height: calc(2rem + 2px);
    border-radius: 2rem;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.outline {
    background-color: #fff;
    color: var(--normal-color);
    border: 1px solid var(--normal-color);
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border-color: var(--green-color-light);
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.outline:focus {
    background-color: #fff;
    border: 1px solid var(--green-color-light);
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
</style>
